<template>
  <!-- 客服页面 -->
  <div class="service">
    <div class="idEntity">
      <h4>身份认证审核</h4>
    </div>
    <div class="theQuery">
      <div class="theQuery-Tow">
        <p>按手机号查询</p>
        <el-input v-model="form.tel" placeholder="请输入内容" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>
      <div class="theQuery-Tow">
        <p>按用户ID查询</p>
        <el-input v-model="form.userId" placeholder="请输入用户ID" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>
      <div class="theQuery-Tow">
        <p>按身份证号查询</p>
        <el-input v-model="form.carId" placeholder="请输入身份证号" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>
      <div class="theQuery-Tow">
        <p>按审核状态查询</p>
        <el-select v-model="form.store" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <!-- <div class="theQuery-Tow">
        <p>按认证等级查询</p>
        <el-select v-model="form.approve" placeholder="请选择">
          <el-option
            v-for="item in attestation"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 52px" @click="doFilter">搜索</el-button>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 50px">
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="userId" label="序号"> </el-table-column>
        <el-table-column prop="tel" label="手机号"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="cardNum" label="身份证号或者护照号"> </el-table-column>
        <el-table-column align="center" label="认证等级">
          <template slot-scope="scope">
            <div>
              {{ scope.row.grade == 1 ? '初级认证' : scope.row.grade == 2 ? '高级认证' : '未认证' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cardFront" label="身份证或者护照正面">
          <template slot-scope="scope">
            <div>
              <el-image style="width: 80px; height: 80px" :src="scope.row.cardFront" :preview-src-list="scope.row.cardFronts"> </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cardAfter" label="身份证反面">
          <template slot-scope="scope">
            <div>
              <el-image style="width: 80px; height: 80px" :src="scope.row.cardAfter" :preview-src-list="scope.row.cardAfters"> </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cardImage" label="手持身份证或者护照">
          <template slot-scope="scope">
            <div>
              <el-image style="width: 80px; height: 80px" :src="scope.row.cardImage" :preview-src-list="scope.row.cardImages"> </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="上传时间"> </el-table-column>
        <el-table-column align="center" label="状态" width="80">
          <template slot-scope="scope">
            <div :class="scope.row.status == 1 ? 'tongguo' : 'butongguo'">
              {{ scope.row.status == 1 ? '通过' : scope.row.status == 2 ? '不通过' : '未审核' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateBy" label="操作人" width="100"> </el-table-column>
        <el-table-column prop="failedReason" label="失败原因" width="180"> </el-table-column>
        <el-table-column prop="zhuangtai" label="操作" width="240">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="success" @click="handleEdit(scope.$index, scope.row.id)" v-show="scope.row.status == 0"
                >通过</el-button
              >
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)" v-show="scope.row.status == 0"
                >不通过</el-button
              >
              <el-button size="mini" type="success" @click="handleDel(scope.$index, scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        tel: '', //手机号查找
        carId: '', //身份证号查找
        approve: '', //认证等级
        store: '', // 审核状态查询
        userId: '' //用户ID
      },
      options: [
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '通过'
        },
        {
          value: 2,
          label: '未通过'
        }
      ],
      // 认证等级
      attestation: [
        {
          value: 2,
          label: '高级'
        },
        {
          value: 1,
          label: '初级'
        },
        {
          value: 0,
          label: '未认证'
        }
      ],
      // 信息
      data: null,
      total: null,
      currentPage: 1,
      pageNumber: null
    }
  },
  created() {
    this.axios.get('/admin/authentication/findAll', {}).then(res => {
      this.data = res.data.data.records
      this.total = res.data.data.total
      this.currentPage = res.data.data.current
      this.pageNumber = res.data.data.size
      // 点击预览图需要数组 对data数组里添加点击需要的数组
      this.data.forEach(item => {
        item.cardFronts = []
        item.cardAfters = []
        item.cardImages = []
        item.cardFronts.push(item.cardFront)
        item.cardImages.push(item.cardImage)
        item.cardAfters.push(item.cardAfter)
      })
    })
  },
  methods: {
    //通过按钮
    handleEdit(index, id) {
      // 通过
      this.axios.get('/admin/authentication/updateStatus', { status: 1, id }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.mesg,
            type: 'success'
          })
          this.data[index].status = 1
          this.data[index].failedReason = ''
        } else {
          this.$message({
            message: res.data.mesg,
            type: 'error'
          })
        }
      })
    },
    //不通过按钮
    handleDelete(index, id) {
      this.$prompt('请输入原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          // 不通过
          this.axios
            .get('/admin/authentication/updateStatus', {
              failedReason: value,
              id,
              status: 2
            })
            .then(res => {
              if (res.data.code == 200) {
                this.data[index].failedReason = value
                this.data[index].status = 2
                this.$message({
                  type: 'success',
                  message: res.data.mesg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.mesg
                })
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })

      console.log(index, id)
    },
    //删除按钮
    handleDel(index, id) {
      this.$confirm('确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        // 不通过
        this.axios
          .get('/admin/authentication/delete', {
            id
          })
          .then(res => {
            if (res.data.code == 200) {
              this.data.splice(index, 1)
              this.$message({
                type: 'success',
                message: res.data.mesg
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.mesg
              })
            }
          })
      })
    },
    // 搜索
    doFilter() {
      this.axios
        .get('/admin/authentication/findAll', {
          cardNum: this.form.carId,
          grade: this.form.approve,
          status: this.form.store,
          tel: this.form.tel,
          userId: this.form.userId
        })
        .then(res => {
          if (res.data.data.records.length) {
            this.$message({
              message: '查询成功',
              type: 'success'
            })
            this.data = res.data.data.records
            this.total = res.data.data.total
            this.currentPage = res.data.data.current
            this.pageNumber = res.data.data.size
            this.data.forEach(item => {
              item.cardFronts = []
              item.cardAfters = []
              item.cardImages = []
              item.cardFronts.push(item.cardFront)
              item.cardImages.push(item.cardImage)
              item.cardAfters.push(item.cardAfter)
            })
          } else {
            this.$message({
              message: '查无数据',
              type: 'warning'
            })
          }
        })
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageNumber = val
      this.axios
        .get('/admin/authentication/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.data = res.data.data.records
          this.data.forEach(item => {
            item.cardFronts = []
            item.cardAfters = []
            item.cardImages = []
            item.cardFronts.push(item.cardFront)
            item.cardImages.push(item.cardImage)
            item.cardAfters.push(item.cardAfter)
          })
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/authentication/findAll', {
          currentPage: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.data = res.data.data.records
          this.data.forEach(item => {
            item.cardFronts = []
            item.cardAfters = []
            item.cardImages = []
            item.cardFronts.push(item.cardFront)
            item.cardImages.push(item.cardImage)
            item.cardAfters.push(item.cardAfter)
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.idEntity {
  h4 {
    margin: 10px;
    color: #409eff;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    margin-right: 45px;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.tongguo {
  color: green;
}
.butongguo {
  color: red;
}
</style>
